import './scripts/common';
import {onFind} from "@elements/init-modules-in-scope";

let _config = window['_config'] || {};

let app = {
    debug: false,
};
window.app = app || {};

// Todo: Add page specific JS here e.g:
import Collapse from 'bootstrap/js/dist/collapse';
onFind('[data-bs-toggle="collapse"]',function (element) {
    new Collapse(element);
});

import Tab from 'bootstrap/js/dist/tab';
onFind('[data-bs-toggle="tab"]',function (element) {
    new Tab(element);
});

import * as lightbox from '@elements/lightbox';
lightbox.init();

import * as anchorNav from '@elements/anchor-nav/bootstrap-5';
anchorNav.init({
        renderItem: function (text, anchor) {
            return '<li class="nav-item anchor-nav__list-item list-inline-item js-anchor-nav__item"><a class="js-anchor-nav__link nav-link anchor-nav__link js-smoothscroll__link" data-scroll-offset="80" href="#' + anchor + '"><span class="anchor-nav__title">' + text + '</span></a></li>';
        },
        smoothScroll: false,
        scrollSpy: false,
        scrollSpyTarget: '.js-anchor-nav',
        scrollSpyOffset: 160
    },
    {
        list: '.js-anchor-nav__list',
        item: '.js-anchor-nav__item'
    }
);

/*
import {find} from "@elements/dom-utils";
import ScrollSpy from './libs/bootstrap/scrollspy';
new ScrollSpy(document.body, {
    target: find('.js-anchor-nav'),
    offset: 80,
    method: 'position'
})
*/
import * as smoothScroll from '@elements/smooth-scroll';
window.onload = function() {
    smoothScroll.init({scrollOffset: 160, initialPosition: true});
}

import * as playVideo from './scripts/play-video';
playVideo.init();

import * as autoplayVideo from './scripts/autoplay-video';
autoplayVideo.init();

import * as tilt from './scripts/tilt';
tilt.init();

import * as anchorNavExtension from './scripts/anchor-nav-extension';
anchorNavExtension.init();

import * as parallax from './scripts/parallax';
parallax.init();

import * as dynamicTabbing from './scripts/dynamic-tabbing';
dynamicTabbing.init();

import * as tabbingAnimations from './scripts/tabbing-animations';
tabbingAnimations.init();

import * as ajaxForm from '@elements/ajax-form';
ajaxForm.init();

import * as ajaxFormScrollTop from './scripts/ajax-form-scroll-top';
ajaxFormScrollTop.init();

import * as overlayToggle from './scripts/overlay-toggle';
overlayToggle.init();

import Popover from 'bootstrap/js/dist/popover';
onFind('[data-bs-toggle="popover"]',function (element) {
    new Popover(element);
});