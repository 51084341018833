"use strict";

import {onFind} from "@elements/init-modules-in-scope";
import 'vanilla-tilt/dist/vanilla-tilt';

export function init () {
    onFind('.js-tilt-animation', function (item) {
        VanillaTilt.init(item, {
            max: 35,
            speed: 100,
            reset: false
        });
    });
}