"use strict";

import {onFind} from "@elements/init-modules-in-scope";
import {closest, findIn} from "@elements/dom-utils";

const selectors = {
    base: '.js-anchor-nav',
    link: '.js-anchor-nav__link',
    list: '.js-anchor-nav__list',
    linkWrapper: '.js-anchor-nav__item'
};

export function init() {
    const isMobile = () => matchMedia('(max-width: 767px)').matches;

    //bs5 fires activate.bs.scrollspy on window
    window.addEventListener('activate.bs.scrollspy', function (evt) {
        onFind(selectors.base, function (anchorNav) {
            if (isMobile()) {
                let activeId = evt.relatedTarget.hash;
                let activeLink = findIn(`[href='${activeId}']`, anchorNav);
                let activeLinkWrapper = closest(selectors.linkWrapper, activeLink);
                let linkList = closest(selectors.list, activeLink);
                center(linkList, activeLinkWrapper);
            }
        })
    });

}

function center(scrollParent, activeLi) {
    if (activeLi) {
        let childPos = activeLi.offsetLeft, // position of Li in scrollparent
            offset = childPos - scrollParent.offsetWidth / 2 + activeLi.getBoundingClientRect().width / 2 - scrollParent.offsetLeft;

        scrollParent.scroll({
            left: offset,
            behavior: "smooth"
        });
    }
}

